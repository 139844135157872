.event-tile {
    @include utils(relative);
    overflow: hidden;

    .event-tile__media {
        @include utils(relative);
        overflow: hidden;
    }

        img,
        video {
            @include utils(w-100, h-100);
            object-fit: cover;
        }

        .event-tile__video {
            left: 0;
        }


    .event-tile__inner {
        @include utils(absolute, inset-0, flex, flex-column, flex-jc-end);
        z-index: 10;
    }

        .event-tile__text {
            @include utils(w-100, flex, flex-column, flex-jc-end);
            @include spacing(pl-4, pb-4, pt-24, pr-16);
            color: color('white');

            a {
                @include utils(no-underline);
                color: color('white');
            }
        }

        .event-tile__headline {
            @include utils(flex-order-2, font-bold, w-100);
        }

        .event-tile__tagline {
            @include utils(flex-order-1, opacity-80, w-100);
            @include render-font-style(h6-small);
            @include spacing(mb-2);
        }

        .event-tile__headline,
        .event-tile__tagline {
            .--compact-tiles & {
                text-shadow: rem(1px) rem(0px) rem(3px) rgba(0,0,0,0.4);
            }
        }


        .event-tile__blurb {
            @include utils(flex-order-3);

            p {
                @include utils(font-medium);
                @include render-font-style(small);
                @include spacing(mt-2);
            }
        }

        .event-tile__shortlist {
            @include utils(absolute);
            @include spacing(p2);
            z-index: 20;
            right: rem(8px);
            bottom: rem(12px);
            color: color('white');

            &:hover {
                color: color('red');
            }

            .--exploded-tiles & {
                @include spacing(pt-0);
                top: 1rem;
                bottom: auto;
                color: color('black');
            }
        }


    &:hover {
        @include utils(cursor-pointer);

        a {
            @include utils(no-underline);
        }
    }

    &.--blurb {

        .--compact-tiles & {
            .event-tile__text {
                display: flex;
                justify-content: flex-end;
                flex-direction: column;
            }

            .event-tile__blurb {
                flex: 0 0 0%;
                height: 0;
                overflow: hidden;
                opacity: 0;
                @include transition(flex opacity, 0.2s, 'out');
            }

            &:hover {
                .event-tile__blurb {
                    flex: auto;
                    height: auto; // left as a fallback for IE 11
                    height: min-content; // fix for Firefox not minimizing height after removing hover
                    opacity: 1;
                    @include transition(flex opacity, 0.15s, 'in');
                }
            }
        }
    }

    &.--reveal {
        .event-tile__image {
            opacity: 1;
            transition: opacity 0.3s $easing-out;
        }

        &:hover {
            .event-tile__image {
                opacity: 0;
                transition: opacity 0.1s $easing-in;
                transition-delay: 0.1s;
            }
        }
    }

    &.--zoom {
        .event-tile__image {
            @include transition(transform, 0.1s, 'out');
        }

        &:hover {
            .event-tile__image {
                transform: scale(1.1);
                @include transition(transform, 0.2s, 'in');
            }
        }
    }

    &.--loading {
        @include utils(bg-grey-10);
        height: rem(688px);
    }

    .--exploded-tiles & {
        .event-tile__inner {
            @include utils(static);
        }

        .event-tile__text {
            @include utils(relative);
            @include spacing(pt-4, pb-24);
            background: none;
            color: inherit;

            a {
                color: inherit;
            }
        }
    }
}

// Modifications when tile placed within a container element (eg, when inline with rest page content)
.container {
    .event-tile__tagline {
        @include breakpoint-only('md') {
            font-size: rem(10px);
        }

        @include breakpoint-only('md+') {
            font-size: rem(11px);
        }
    }

    .event-tile__shortlist {
        @include breakpoint-between('md', 'md+') {
            right: rem(4px);
            bottom: rem(4px);
        }
    }

    .event-tile__text {
        @include breakpoint-between('md', 'md+') {
            padding-right: rem(48px);
            padding-bottom: rem(12px);
            padding-left: rem(12px);
        }
    }
}



.provocation-tile {
    @include utils(relative);
    background-color: color('mint');
    background-color: var(--theme-color);

    h2 {
        @include spacing(py-8, pl-8, pr-12);
        @include utils(uppercase, color-white);
        font-family: $font-family-alt;
        line-height: 1;
    }

    em,
    i {
        @include utils(underline, not-italic, font-normal);
    }

    a {
        @include utils(no-underline);
        color: color('white');
    }

    &:not(.no-hover):hover {
        background-color: color('black');

        em,
        i {
            color: color('mint');
            color: var(--theme-color);
        }
    }
}
