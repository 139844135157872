// For stuff you just don't know where to put.

.culture-club-large-price {
    @include utils(font-light);
    font-size: rem(48px);

    @include breakpoint-up('md') {
        font-size: rem(56px);
    }

    @include breakpoint-up('md+') {
        font-size: rem(84px);
    }

    @include breakpoint-up('lg') {
        font-size: rem(96px);
    }
}

.culture-club {
    .content_stream_block {
        .btn--default {
            @extend .btn--culture;
            @extend .btn--medium;
        }
    }
}

.btn--add-and-remove {
    min-width: rem(100px); // Approx length of 'Remove', stops button jumping widths when text changes
}

.ticket-summary-wrapper {
    @include breakpoint-up('md+') {
        @include spacing(mb-8);
        @include utils(sticky);
        top: rem(96px); // TODO: Magic number! This is the height of the header
    }
}

    .shortlist-alert{
        @include utils(relative, text-center);
        background-color: color('mint');
        background-color: var(--theme-color);
        @include spacing(p4, pb-6);

        @include breakpoint-up('md') {
            @include spacing(p6, pb-8);
        }

        &:before{
            content: "";
            @include utils(block, absolute, w-0, h-0);
            top: 0;
            left: 50%;
            transform: translate(-50%, -100%);
            border-left: rem(8px) solid transparent;
            border-right: rem(8px) solid transparent;
            border-bottom: rem(8px) solid color('mint');
            border-bottom-color: var(--theme-color);
        }
    }

    .deal-alert {
        @include utils(relative, text-center, flex-grow-1);
        @include spacing(py-3, px-4, m1); // Needs margin all around because it sits within .btn-lockup
        background-color: color('grey-10');

        // Smaller than this breakpoint, it's ticky to line up the arrow (described below)
        // with the Buy Tickets button, so it's just hidden.
        @include breakpoint-up('md+') {
            &:before {
                content: "";
                @include utils(block, absolute, w-0, h-0);
                top: 0;
                left: 25%;
                transform: translate(-50%, -100%);
                border-left: rem(6px) solid transparent;
                border-right: rem(6px) solid transparent;
                border-bottom: rem(6px) solid color('grey-10');
            }
        }
    }



.share-widget__button {
    @extend .link--stubby;
    @include spacing(pl-0, mt-4);
}




// Partially hidden event_body element
.u-read-more { // This class name is used by JS
    position: relative;
    -webkit-transition: max-height .75s;
    transition: max-height .75s;
    overflow: hidden;

    @include breakpoint-down('sm+') {
        margin-bottom: rem(48px);

        &.-is-hidden {
            max-height: 25em;

        }

        &.-is-hidden:before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 10em;
            background-image: -webkit-linear-gradient(bottom,#fff 2em,#fff0 100%);
            background-image: linear-gradient(0deg,#fff 2em,#fff0 100%);
            z-index: 1;
        }

    }
}

.read-more--visible {
    padding-bottom: 70px;
}

.read-more {
    position: absolute;
    bottom: 0;
    left: auto;
    z-index: 1;
    cursor: pointer;

    @include breakpoint-up('md') {
        display: none;
    }

}

.banner-image-credit {
    p {
        color: color('grey');
        background-color: transparent;
        @include spacing(mt-2, pl-0);
        @include utils(text-right, inline-block);
    }

    &.--event {
        p {
            @include breakpoint-down('sm+') {
                @include spacing(p1, mt-0);
                color: color('grey-30');
                background-color: rgba(0, 0, 0, 0.7);
            }
        }

        .container {
            @include breakpoint-down('sm+') {
                @include spacing(px-0);
                line-height: 1.3; // Shame! Shame! Shame! It's late on go-live night, alright..
            }
        }
    }
}


.sponsor-image-wrapper {
    flex: 1 1 rem(120px);
    max-width: rem(120px);

    @include breakpoint-up('sm+') {
        flex: 1 1 rem(160px);
        max-width: rem(160px);
    }

    .footer & {
        img {
            max-height: rem(100px);
        }
    }

    .--footer-sponsor-2 & {
        padding-top: rem(8px);
    }

    &.--octave {
        padding-top: rem(4px);

        @include breakpoint-up('md') {
            flex: 1 1 rem(130px);
            max-width: rem(130px);
        }
    }
}

.sponsor-group {
    .sponsor {
        flex: 1 1 50%;
        max-width: 50%;

        @include breakpoint-up('sm+') {
            flex: 1 1 33.3%;
            max-width: 33.3%;
        }

        @include breakpoint-up('md') {
            flex: 1 1 25%;
            max-width: 25%;
        }

        img {
            width: auto;
            max-height: rem(220px);
        }
    }
}


.country-company {
    line-height:1.3;

    &.--top {
        padding-top: rem(6px);
    }
}


// Finnicky icon placement classes
.top-n1px { top: -1px; }
.top-n2px { top: -2px; }