.tag {
    @include utils(border2, border-black, color-black, font-bold, inline-block, relative, no-underline);
    @include spacing(pt-1, px-2, mr-2, mb-2);
    font-size: rem(16px);
}

    // Only if a link..
    a.tag {
        &:after {
            content: "";
            @include utils(block, absolute, w-100, h-100);
            background-color: transparent;
            z-index: -1;
        }

        &:hover {
            @include utils(no-underline, color-black);

            &:after {
                background-color: color('grey-40');
                background-color: var(--theme-color);
                top: rem(6px);
                left: rem(6px);
            }
        }

        &:nth-child(4n+1):hover:after {
            top: rem(6px);
            left: rem(6px);
        }
        &:nth-child(4n+2):hover:after {
            top: rem(-6px);
            left: rem(-6px);
        }
        &:nth-child(4n+3):hover:after {
            top: rem(6px);
            left: rem(-6px);
        }
        &:nth-child(4n+4):hover:after {
            top: rem(-6px);
            left: rem(6px);
        }
    }


.flags {
    @include utils(absolute, top-0, left-0);
    line-height: 1;
}

.flag {
    @include utils(inline-block, uppercase, color-white, bg-black, font-medium);
    padding: rem(6px) rem(6px) rem(4px);
    font-size: rem(10px);
    line-height: 1;

    @include breakpoint-up(md) {
        font-size: rem(12px);
    }

    &:nth-child(2) {
        background-color: color('grey-80');
    }

    &:nth-child(3) {
        background-color: color('grey-60');
    }
}
