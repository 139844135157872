.calendar-wrapper { // <div>
    @include utils(flex-fill);
    background-color: color('grey-5');
    margin-left: rem(-$container-spacing-sm);
    margin-right: rem(-$container-spacing-sm);

    @include breakpoint-only('md') {
        @include spacing(mt-8);
        margin-left: rem(-$container-spacing-md);
        margin-right: rem(-$container-spacing-md);
    }

    @include breakpoint-up('md+') {
        @include utils(w-100);
        @include spacing(mt-8, mx-0);
        background-color: color('white');

        .bg-grey-5 & {
            background-color: color('grey-5');
        }
    }

    @include breakpoint-up(md) {
        @include utils(block);
    }
}

.calendar--grid {
    display: -ms-grid;
    display: grid;
    grid-template-rows: auto auto;
    grid-auto-flow: row;
    grid-gap: 0 rem(4px);

    padding-left: rem($container-spacing-sm);

    @include breakpoint-up('md') {
        padding-left: rem($container-spacing-md);
    }

    @include breakpoint-up('md+') {
        padding-left: 0;
    }

    @include breakpoint-down('md') {
        @include nice-scrollbars;
        @include spacing(pt-4, pb-8);
        @include utils(relative);
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        scroll-behavior: smooth;
    }
}

    .calendar__month { // <div>
        grid-column-start: auto;

        h6 {
            @include render-font-style(h6-small);
            @include spacing(mb-2, mr-2);
            @include utils(inline-block, sticky, capitalize);
            color: color('grey');
            left: 0;

            @include breakpoint-up('lg') {
                @include utils(static);
            }
        }
    }



    .calendar__day { // <div>
        @include utils(bg-white, border-white, border2, relative, text-center);
        @include spacing(py-2);
        min-width: rem(56px);

        -ms-grid-row: 2;

        @for $i from 1 through 24 {
            &:nth-of-type(#{$i}n) {
                -ms-grid-column: $i;
            }
        }


        &:last-child {
            // Required to create a gap at the end of the overflow container (on mobile)
            margin-right: rem($container-spacing-sm);
        }

        @include breakpoint-only('md') {
            &:last-child {
                // Required to create a gap at the end of the overflow container (on mobile)
                margin-right: rem($container-spacing-md);
            }
        }

        @include breakpoint-up('md+') {
            // @include utils(bg-grey-5, border-grey-5);
            min-width: 0;

            &:last-child {
                margin-right: 0;
            }
        }

        p {
            @include utils(color-black);
        }

            span {
                font-size: rem(14px);
            }

            a {
                @include utils(color-black, no-underline, font-medium);
                // @include render-font-style(large);
                font-size: rem(24px);

                @include breakpoint-up('lg') {
                    font-size: rem(20px);
                }
            }

        &.--weekend {
            @include utils(bg-grey-10, border-grey-10);
            // background-color: color('mint');
            // background-color: var(--theme-color);
            // border-color: color('mint');
            // border-color: var(--theme-color);

        }

        &.--disabled {
            &,
            &:hover {
                @include utils(bg-grey-5, border-grey-5);
            }

            p, a {
                @include utils(color-grey-30, line-through);
            }

            a {
                @include utils(cursor-not-allowed);
            }

            @include breakpoint-up('lg') {
                &,
                &:hover {
                    @include utils(bg-white, border-white);
                }
            }
        }

        &.--selected {
            &,
            &:hover {
                @include utils(bg-black, border-black);

                p, a {
                    @include utils(color-white);
                }
            }
        }

        &:hover {
            @include utils(border-black);
        }
    }



/* -------------------------------------------------- */
// These two values are linked
.filter-group--mobile-controls.--calendar {
    @include breakpoint-down('sm+') {
        @include spacing(mb-4);
    }
}

.calendar-wrapper {
    //
    //    mb-4 (from .filter-group--mobile-controls.--calendar)
    // +  pb-2 (from .link--stubby)
    // -------
    // = mt-n6 (used here)
    @include breakpoint-down('sm+') {
        @include spacing(mt-n6);
    }
}
/* -------------------------------------------------- */


