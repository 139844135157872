html {
    // don't change this (•̀o•́)ง
    // adjust default font size using rems in the body.
    font-size: 1rem; // 100% of browser preference
    line-height: $line-height-default;
    font-family: $font-family-base;
    font-weight: $font-weight-normal;
    color: $body-color;
}

strong {
    font-weight: $font-weight-medium;
}


body,
.font-default {
    @include render-font-style(default);
}

.super {
    @include render-font-style(super);
}

.xlarge {
    @include render-font-style(xlarge);
}


.large {
    @include render-font-style(large);
}

.small {
    @include render-font-style(small);
}

.tiny {
    @include render-font-style(tiny);
}

.h1,
h1 {
    @include render-font-style(h1);
}

.h2,
h2 {
    @include render-font-style(h2);
}

.h3,
h3 {
    @include render-font-style(h3);
}

.h4,
h4 {
    @include render-font-style(h4);
}

.h5,
h5 {
    @include render-font-style(h5);
}

.h6,
h6 {
    @include render-font-style(h6);
}

.h6.small,
h6.small {
    @include render-font-style(h6-small);
}

.heading-super-text {
    font-size: 12px;
    vertical-align: top;
    top: 3px;
    left: -7px;

    @include breakpoint-up(lg) {
        font-size: 16px;
        top: 3px;
        left: -7px;
    }
}

.h5-sussie {
    font-weight: $font-weight-medium;
    font-family: $font-family-base;
}
