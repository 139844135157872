$font-path: '/static/fonts/';

// Druk 400/normal
@font-face {
    font-family: 'Druk';
    src: url('#{$font-path}/DrukWide-Medium-Web.woff2') format('woff2'),
        url('#{$font-path}/DrukWide-Medium-Web.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 1px;
}

// Suisse 400/normal weight front
@font-face {
    font-family: 'Suisse';
    src: url('#{$font-path}/SuisseIntl-Regular-WebM.woff2') format('woff2'),
        url('#{$font-path}/SuisseIntl-Regular-WebM.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}

// Suisse 500/medium weight font
@font-face {
    font-family: 'Suisse';
    src: url('#{$font-path}/SuisseIntl-Medium-WebM.woff2') format('woff2'),
        url('#{$font-path}/SuisseIntl-Medium-WebM.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
}

// Suisse Book 400/normal weight serif font (alt font)
@font-face {
    font-family: 'Suisse Book';
    src: url('#{$font-path}/SuisseIntl-Book-WebM.woff2') format('woff2'),
        url('#{$font-path}/SuisseIntl-Book-WebM.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}
