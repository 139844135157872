// Fix to ensure that the .header class does not put padding over the site_wide_banner partial
.site-wide-banner {
    position: relative;
    z-index: 1000;
}

.header {
    @include utils(w-100, relative);
    background-color: color('white');
    color: color('black');
    z-index: 100;

    .mobile-menu--visible & {
        background-color: color('black');
        color: color('white');
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);

        & a {
            color: color('white') !important;
        }
    }

    @include utils(sticky, top-0);

        .header-inner { // <div>
            padding: rem(6px) 0 rem(1px);

            @include breakpoint-up('md') {
                @include spacing(py-3)
            }
        }


    //#nzf-logo { // <svg>
    //    width: rem(135px);
    //    height: rem(48px);
    //
    //    @include breakpoint-up(md) {
    //        width: rem(140px);
    //        height: rem(45px);
    //    }
    //}

    .mobile-menu--visible {
        #nzf-logo-mobile {
            fill: color('white');
        }
    }

        #logo-primary {
            .mobile-menu--visible & {
                fill: color('white');
            }
        }

        #logo-tab {
            fill: color('mint');
            fill: var(--theme-color);
        }

        #logo-secondary {
            .mobile-menu--visible & {
                fill: color('black');
            }
        }
}


.nav { // <ul>

    a {
        @include utils(block);
        @include spacing(p3);

        @include breakpoint-up('md+') {
            @include spacing(p4);
        }

    }

    .nav__shortlist,
    .nav__search {
        // @include spacing(p3);
        @include utils(relative);

        @include breakpoint-only('md') {
            @include spacing(p2);
        }
    }

    .nav__search {
        @include spacing(ml-1, mr-n1);

        @include breakpoint-up('md') {
            @include spacing(mx-0);
        }
    }

    &.--small {
        @include spacing(pt-4, pb-12);
        @include utils(list-none);
        left: 0;
        right: 0;

        // All links in small nav (incl sub-nav)
        a {
            color: color('white');
        }

        // Only top-level links in small nav
        & > li > a {

        }
    }

    &.--large {
        @include utils(flex, flex-ai-center);
        margin-bottom: rem(7px); // This accounts for the bottom offset nature of the logo

        @include breakpoint-up('md') {
            margin-bottom: 0;
        }

        // All links in large nav (incl sub-nav)
        a {
            color: color('black');
        }

        // Only top-level links in large nav
        & > li > a {
            @include utils(font-bold, no-underline);
            font-size: rem(16px);

            @include breakpoint-up('md') {
                &:hover,
                &.collapse-control--open {
                    background-color: color('grey-5');
                }
            }
        }
    }
}

    .sub-nav { // <ul>

        a {
            @include utils(no-underline, font-medium);
            @include spacing(px-4);
            line-height: 1.35;

            span {
                @include utils(inline-block, border2-bottom);
                border-bottom-color: color('transparent');
            }

            &:hover {
                span {
                    border-bottom-color: color('black');
                }
            }
        }


        .--small & { // <ul>
            @include spacing(mb-4, py-4);
            background-color: rgba(255, 255, 255, 0.1);

            a {
                opacity: 0.8;
                @include spacing(py-2);
                @include utils(inline-block);

                &:hover {
                    span {
                        border-bottom-color: color('white');
                    }
                }
            }
        }

        .--large & { // <ul>
            @include utils(absolute, flex-wrap, flex-jc-center, bg-white, border2, border-black);
            @include spacing(py-2);
            width: rem(280px);
            top: 100%;
            z-index: 10;

            a {
                @include utils(block);
                @include spacing(py-1);

                &:hover {
                    span {
                        border-bottom-color: color('black');
                    }
                }
            }

            // Pseudo element to create CSS trianlge (two required to create faux-border)
            &:before,
            &:after {
                content: "";
                @include utils(block, absolute, w-0, h-0);
                top: 0;
                left: rem(24px);
                transform: translate(-50%, -100%);
            }

            &:before {
                border-left: rem(10px) solid transparent;
                border-right: rem(10px) solid transparent;
                border-bottom: rem(10px) solid color('black');
            }

            &:after {
                border-left: rem(8px) solid transparent;
                border-right: rem(8px) solid transparent;
                border-bottom: rem(8px) solid color('white');
                z-index: 1;
            }
        }
    }


    .notification {
        $counter-dimension: 18px;
        $counter-line-height: 20px;

        @include utils(absolute, rounded-circle, font-medium, color-white, bg-red, inline-block, text-center);
        font-size: rem(13px);
        width: rem($counter-dimension);
        height: rem($counter-dimension);
        line-height: rem($counter-line-height);
        top: rem(8px);
        right: rem(2px);

        &.--update {
            animation: pulse 0.2s cubic-bezier(0.550, 0.085, 0.680, 0.530) 2s;
        }

        @include breakpoint-up('md') {
            top: rem(3px);
            right: rem(-1px);
        }

        @include breakpoint-up('md+') {
            top: rem(9px);
            right: rem(4px);
        }
    }


#burger {
    height: 22px;
    padding: rem(6px) rem(14px);
    margin-top: 2px;
    position: relative;
    transition: .5s ease-in-out;
    cursor: pointer;
    transform: rotate(0deg);

    span {
        @include utils(block, absolute, w-100, opacity-100);
        background: color('black');
        height: rem(3px);
        display: block;
        opacity: 1;
        left: 0;
        transition: .25s ease-in-out;
        transform: rotate(0deg);

        &:nth-child(1) { top: 0; }
        &:nth-child(2) { top: rem(8px); }
        &:nth-child(3) { top: rem(8px); }
        &:nth-child(4) { top: rem(16px); }
    }
}


#mobile-menu {
    transform: translateY(-100%);
    z-index: 99;
}

        .mobile-nav-wrapper {
            $mobile-header-height: 68px; // TODO: Magic number! Related to height of header element

            @include spacing(pt-4, pb-12);
            @include utils(absolute);
            overflow: auto;
            -webkit-overflow-scrolling: touch;
            top: $mobile-header-height;
            height: calc(100vh - #{$mobile-header-height});
            left: 0;
            right: 0;
            bottom: 0;
        }

        .mobile-menu--visible {
            overflow: hidden;
            position: fixed;
            .site-wide-banner {
                display: none;
            }

            #burger {
                span {
                    background-color: color('white');
                }

                span:nth-child(1) {
                    top: 16px;
                    width: 0;
                    left: 50%;
                }

                span:nth-child(2) {
                    transform: rotate(45deg);
                }

                span:nth-child(3) {
                    transform: rotate(-45deg);
                }

                span:nth-child(4) {
                    top: 16px;
                    width: 0;
                    left: 50%;
                }
            }

            #mobile-menu {
                transform: translateY(0);
            }
        }


// Animation for adding item to shortlist. TODO: Make this aninmation more prominent
@keyframes pulse {
  from {
            transform: scale(1);
            transform-origin: center center;
            animation-timing-function: ease-out;
  }
  50% {
            transform: scale(1.6);
            animation-timing-function: ease-in;
  }
  100% {
            transform: scale(1);
            animation-timing-function: ease-in;
  }
}
